// @flow
import {bindAll, extend} from '../../util/util';
import {Evented} from '../../util/evented';
import type Map from '../map';
import type {LngLatLike} from "../../geo/lng_lat";
import {v4 as uuidv4} from 'uuid';
import {MapMouseEvent} from '../events';

type Options = {
    paths: LngLatLike[][],
    strokeColor: string,
    strokeOpacity: number,
    strokeWeight: number,
    fillColor: string,
    fillOpacity: number,
    zIndex: number,
    clickable: boolean,
    draggable: boolean,
    editable: boolean,
    visible: boolean
};

const defaultOptions: Options = {
    paths: [[]],
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    zIndex: null,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true
};

const MAPPING_LAYER_PROPERTY: Map<string, string> = {
    strokeColor: 'line-color',
    strokeOpacity: 'line-opacity',
    strokeWeight: 'line-width',
    fillColor: 'fill-color',
    fillOpacity: 'fill-opacity',
};

/**
 * Creates a polygon component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a polygon. The default is a light blue, droplet-shaped SVG polygon.
 * @param {string} [options.anchor='center'] A string indicating the part of the Polygon that should be positioned closest to the coordinate set via {@link Polygon#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default polygon if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a polygon is able to be dragged to a new position on the map.
 * @example
 * var polygon = new mapboxgl.Polygon()
 *   .setLngLat([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-polygon-icons/)
 * @see [Create a draggable Polygon](https://www.mapbox.com/mapbox-gl-js/example/drag-a-polygon/)
 */
export default class Polygon extends Evented {
    _map: Map;
    options: Options;
    _canvas: HTMLElement;
    _paths: LngLatLike[][];
    SOURCE_ID: string;
    OUTLINE_ID: string;
    _mapLoadedInterval: number;

    constructor(options?: Options) {
        super();
        this.options = extend({}, defaultOptions, options);
        this.SOURCE_ID = `POLYGON_${uuidv4()}`;
        this.OUTLINE_ID = `${this.SOURCE_ID}_OUTLINE`;
        this._paths = this.options.paths;
        this._mapLoadedInterval = null;

        bindAll([
            '_addSource',
            '_connect',
            '_onMouseEnter',
            '_onMouseLeave',
            '_onClick',
            '_onDoubleClick'
        ], this);
    }

    _connect() {
        this._map.off('load', this._connect);
        clearInterval(this._mapLoadedInterval);
        this._addSource();
        this.setClickable(this.options.clickable);
    }

    /**
     * Attaches the polygon to a map
     * @param {Map} map
     * @returns {Polygon} `this`
     */
    addTo(map: Map) {
        this.remove();
        this._map = map;
        this._canvas = map.getCanvasContainer();

        if (map.loaded()) {
            this._connect();
        } else {
            map.on('load', this._connect);
            this._mapLoadedInterval = setInterval(() => {
                if (map.loaded()) this._connect();
            }, 16);
        }

        return this;
    }

    /**
     * Removes the polygon from a map
     * @example
     * var polygon = new vtmapgl.Polygon().addTo(map);
     * polygon.remove();
     * @returns {Polygon} `this`
     */
    remove() {
        if (this._map) {
            this._map.off('load', this._connect);
            clearInterval(this._mapLoadedInterval);
            this.setClickable(false);

            if (this._map.getLayer(this.OUTLINE_ID)) {
                this._map.removeLayer(this.OUTLINE_ID);
            }
            if (this._map.getLayer(this.SOURCE_ID)) {
                this._map.removeLayer(this.SOURCE_ID);
            }
            if (this._map.getSource(this.SOURCE_ID)) {
                this._map.removeSource(this.SOURCE_ID);
            }
            delete this._map;
        }
        return this;
    }

    _addSource() {
        this._map.addSource(this.SOURCE_ID, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: this._paths
                }
            }
        });
        this._map.addLayer({
            id: this.SOURCE_ID,
            type: 'fill',
            source: this.SOURCE_ID,
            layout: {},
            paint: {
                'fill-color': this.options.fillColor,
                'fill-opacity': this.options.fillOpacity,
            }
        });
        // Draw outline
        this._map.addLayer({
            id: this.OUTLINE_ID,
            type: 'line',
            source: this.SOURCE_ID,
            layout: {},
            paint: {
                'line-color': this.options.strokeColor,
                'line-width': this.options.strokeWeight,
                'line-opacity': this.options.strokeOpacity,
            }
        });
    }

    _onMouseEnter(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = 'pointer';
    }

    _onMouseLeave(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = '';
    }

    _onClick(e: MapMouseEvent | MapTouchEvent) {
        e.preventDefault();
        this.fire('click', e);
    }

    _onDoubleClick(e: MapMouseEvent | MapTouchEvent) {
        e.preventDefault();
        this.fire('dblclick', e);
    }

    /**
     * Same setPath
     * @param {LngLatLike[][]} paths
     */
    setPaths(paths: LngLatLike[][]) {
        this._paths = paths;
        this._updateSource();
    }

    /**
     * Same setPaths
     * @param {LngLatLike[][]} path
     */
    setPath(path: LngLatLike[][]) {
        this._paths = path;
        this._updateSource();
    }

    getPath() {
        return JSON.parse(JSON.stringify(this._paths));
    }

    getPaths() {
        return JSON.parse(JSON.stringify(this._paths));
    }

    _updateSource() {
        const source = this._map.getSource(this.SOURCE_ID);
        if (source) {
            source.setData({
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: this._paths
                }
            });
        }
    }
    setPaints(options: any) {
        if (!this._map.getLayer(this.SOURCE_ID)) {
            return;
        }
        const possibleProperties = Object.keys(MAPPING_LAYER_PROPERTY);
        Object.entries(options).forEach(([key, value]) => {
            if (!possibleProperties.includes(key)) return;
            const layerProperty = MAPPING_LAYER_PROPERTY[key];
            if (layerProperty.startsWith('fill')) {
                this._map.setPaintProperty(this.SOURCE_ID, layerProperty, value);
            }
            if (layerProperty.startsWith('line')) {
                this._map.setPaintProperty(this.OUTLINE_ID, layerProperty, value);
            }
        });
    }

    setClickable(clickable: boolean) {
        this.options.clickable = clickable;
        if (this._map) {
            if (this.options.clickable) {
                // Change the cursor to a pointer when the mouse is over the places layer.
                this._map.on('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                // Change it back to a pointer when it leaves.
                this._map.on('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                this._map.on('click', this.SOURCE_ID, this._onClick);
                this._map.on('dblclick', this.SOURCE_ID, this._onDoubleClick);
            } else {
                this._map.off('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                // Change it back to a pointer when it leaves.
                this._map.off('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                this._map.off('click', this.SOURCE_ID, this._onClick);
                this._map.off('dblclick', this.SOURCE_ID, this._onDoubleClick);
            }
        }
        return this;
    }
}
