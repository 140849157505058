import { API_DOMAIN, FILE_DOMAIN } from './config';

const mediaDomain = API_DOMAIN + 'mapservice/v1/media/';

export const STYLES = {
    NORMAL: mediaDomain + 'v4-style.json',
    VTRANS: mediaDomain + 'v4-style.json',
    VNIGHT: mediaDomain + 'v4-style-night.json',
    VADMIN: mediaDomain + 'v4-style-admin.json',
    GTRANS: mediaDomain + 'v4-gtrans_style.json',
    GSAT: mediaDomain + 'v4-gsat_style.json',
}

export const MAP_STYLE_ID = {
    VTRANS: 'VTRANS',
    VADMIN: 'VADMIN',
    VNIGHT: 'VNIGHT',
    GTRANS: 'GTRANS',
    GSAT: 'GSAT',
}

export const MAP_STYLE_CONFIG = {
    [MAP_STYLE_ID.VTRANS]: {
        miniMapUrl: FILE_DOMAIN + 'style/layer_vtrans.png',
        title: 'Giao thông',
        engTitle: 'Transport'
    },
    [MAP_STYLE_ID.VADMIN]: {
        miniMapUrl: FILE_DOMAIN + 'style/layer_vadmin.png',
        title: 'Hành chính',
        engTitle: 'Administrative'
    },
    [MAP_STYLE_ID.VNIGHT]: {
        miniMapUrl: FILE_DOMAIN + 'style/layer_vnight.png',
        title: 'Ban đêm',
        engTitle: 'Night'
    },
    [MAP_STYLE_ID.GTRANS]: {
        miniMapUrl: FILE_DOMAIN + 'style/layer_gtrans.png',
        title: 'GGiao thông',
        engTitle: 'GG Transport'
    },
    [MAP_STYLE_ID.GSAT]: {
        miniMapUrl: FILE_DOMAIN + 'style/layer_gsat.png',
        title: 'Vệ tinh',
        engTitle: 'Satellite'
    }
}
